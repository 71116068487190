import {graphql} from "gatsby";
import React from "react";
import Layout from "../components/Layout";
import Banner from "../components/Banner";
import {PortableText} from "@portabletext/react";
import {imageUrl} from "gatsby-plugin-sanity-image";
import {SEO} from "../components/SEO";

export default function NewsArticle({data}) {
    let imgUrl = data.sanityNews.image?.asset ? imageUrl(data.sanityNews.image.asset) : 'https://placehold.jp/500x500.png?text=No%20Picture'

    return (
        <Layout title={data.sanityNews.title}>
            <Banner heightSmall={true} backgroundImage={imgUrl}
                    heirarchy={[["Home", "/"], ["Newsroom", "/newsroom"], [data.sanityNews.title, "/newsroom/" + data.sanityNews.id]]}>
                <div style={{color: "white", width: "50%"}}>
                    <h1 style={{fontSize: "3rem", fontWeight: "bold"}}>{data.sanityNews.title}</h1>
                </div>
            </Banner>

            <article className="section">
                <h1>{data.sanityNews.title}</h1>
                <div>{new Date(data.sanityNews._createdAt).toLocaleDateString("en-US", {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                })}</div>
                <PortableText value={data.sanityNews._rawArticle}/>
            </article>

        </Layout>
    )
}

export const Head = ({data}) => {
    let imgUrl = data.sanityNews.image?.asset ? imageUrl(data.sanityNews.image.asset) : 'https://placehold.jp/500x500.png?text=No%20Picture'

    return (
        <SEO customImage={imgUrl} title={data.sanityNews.title}></SEO>
    )
}

export const query = graphql`
query (
    $id: String
  ) {
    sanityNews(id: { eq: $id }) {
      title
        tag
        id
        _rawArticle
        _createdAt
        image {
              ...ImageWithPreview
            }
    }
  }
`
